@import 'src/styles/mixins';

.select {
	&-arrow {
		position: absolute;
		right: 0.5rem;
		top: 50%;
		translate: 0 -50%;

		box-sizing: content-box;
		width: 1.1rem;
		height: 1.1rem;
		margin-top: 0.2em;
		padding: 1rem 2rem;

		user-select: none;
		cursor: pointer;

		transition: 0.3s;

		&.disabled {
			opacity: 0.7;
			user-select: none;
		}
	}

	&-preloader {
		flex-shrink: 0;
		position: absolute;
		right: 0;
		top: 50%;
		translate: -50% -50%;
		z-index: 4;

		margin-right: 0.8em;

		width: 1.75rem;
		height: 1.75rem;
		animation: spin 2s infinite linear;
	}

	&__droplist {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		z-index: 2;

		visibility: hidden;
		flex-shrink: 0;
		display: flex;
		margin-top: 1rem;
		top: 100%;
		min-width: 100%;
		width: fit-content;
		max-height: 40rem;

		box-shadow:
			0px 9px 111px rgba(0, 0, 0, 0.05),
			0px 1.01364px 28.7341px rgba(0, 0, 0, 0.0308023);
		border-radius: 0.5rem;
		background-color: white;

		user-select: none;
		transition:
			visibility,
			opacity 0.1s;

		&.active {
			visibility: visible;
			user-select: all;
		}

		ul {
			width: fit-content;
			min-width: 100%;
			display: flex;
			flex-direction: column;

			margin: 0;
			padding: 0;
			overflow-y: auto;

			&.short {
				height: fit-content;
				min-height: fit-content;
				max-height: fit-content;
			}

			&.long {
				height: 30rem;
				min-height: 20rem;
				max-height: 34rem;
			}

			&.loading {
				min-height: 6rem;
				opacity: 0.6;

				li {
					pointer-events: none;
				}
			}

			li {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				padding: 1rem;
				width: 100%;
				cursor: pointer;
				min-height: 4rem;

				text-align: center;
				list-style: none;
				user-select: none;

				span {
					user-select: none;
					font-family: 'Lato';
					font-style: normal;
					font-weight: 400;
					font-size: 1.6rem;
					line-height: 110%;
					letter-spacing: 0.01em;
					text-align: left;
				}

				&:hover {
					background-color: #f0f6ff;
				}
			}
		}
	}

	&__container {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 0.8rem;
		position: relative;

		.error-text {
			position: absolute;
			top: 100%;
			margin-top: 0.2rem;

			color: #e64343 !important;
		}

		&.error {
			.select__plate {
				border: 1px solid #e64343;
			}
		}

		&.loading {
			background: pink;
		}

		@include sm {
			gap: 1.4rem;
		}

		span {
			font-weight: 400;
			font-size: 1.4rem;
			line-height: 140%;

			color: rgba(10, 38, 83, 0.64);
		}

		input {
			font-family: 'Lato';
			font-style: normal;
			font-weight: 400;
			font-size: 1.6rem;
			line-height: 140%;
			letter-spacing: 0.01em;
			color: black;

			padding-right: 4rem;
			text-overflow: ellipsis;

			&.disabled {
				background-color: #f5f5f5;
				color: #9e9e9e;
				user-select: none;

				+ svg {
					opacity: 0.4;
					user-select: none;
				}
			}

			&::placeholder {
				user-select: none;
				color: rgba(10, 38, 83, 0.3);
			}
		}
	}

	&__plate {
		display: flex;
		height: 4.8rem;
		width: 100%;
		padding: 1.4rem;
		// padding-right: 3em;

		background: #f0f6ff;
		border: none;
		border-radius: 1rem;
		cursor: pointer;

		@include sm {
			height: fit-content;
		}

		font-family: 'Lato';
		color: rgba(10, 38, 83, 0.3);
		font-style: normal;
		font-weight: 400;
		font-size: 1.6rem;
		line-height: 140%;
		letter-spacing: 0.01em;

		&.disabled {
			opacity: 0.7;
			user-select: none;
		}
	}
}

@keyframes spin {
	0% {
		rotate: 0deg;
	}
	100% {
		rotate: 360deg;
	}
}
