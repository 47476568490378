@import '../../styles/mixins';
@import 'react-widgets/styles.css';

.rw-widget-picker {
	min-height: 34px;
}

.date-picker {
	&__container {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 0.8rem;
		position: relative;

		&.disabled {
			input {
				// opacity: 0.7;
				background-color: #f5f5f5 !important;
				color: #9e9e9e !important;
			}
		}

		.error-text {
			margin-top: 0.2rem;
			position: absolute;
			top: 100%;

			font-size: 1.4rem;
			color: #e64343 !important;
		}

		&.error {
			.masked-input {
				border: 1px solid #e64343;
			}
		}

		&_label {
			font-size: 1.4rem;
			font-weight: 400;
			line-height: 140%;

			color: rgba(10, 38, 83, 0.64);
		}

		input {
			display: flex;
			height: 4.8rem;
			max-height: 4.8rem;
			width: 100%;
			padding: 1.4rem;

			background: #f0f6ff;
			border-radius: 1rem;
			border: none;
			outline: none;

			font-family: 'Lato';
			font-style: normal;
			font-weight: 400;
			font-size: 1.6rem;
			line-height: 140%;
			letter-spacing: 0.01em;

			&::placeholder {
				font-family: 'Lato';
				font-style: normal;
				font-weight: 400;
				font-size: 1.6rem;
				line-height: 140%;
				letter-spacing: 0.01em;

				color: rgba(10, 38, 83, 0.3);
			}
		}

		.rw-popup {
			left: unset;
			right: 0;
			margin-top: 1rem;
			width: 100%;
		}

		.rw {
			&-state-focus {
				outline: none;
			}

			&-widget {
				&-input {
					box-shadow: unset;
				}

				&-picker {
					box-shadow: unset;
				}

				&-container {
					position: relative;

					background: #f0f6ff;
					border-radius: 1rem;
					border: none;
					outline: none;
					height: 4.8rem;
					max-height: 4.8rem;

					font-family: 'Lato';
					font-style: normal;
					font-weight: 400;
					font-size: 1.6rem;
					line-height: 140%;
					letter-spacing: 0.01em;

					button {
						z-index: 1;
						margin: 0 !important;
						display: flex;
						justify-content: center;
						align-items: center;
						position: absolute;
						border: none !important;
						right: 0.85rem;
						top: 50%;
						transform: translateY(-50%);
						padding: 1rem !important;
						padding-right: 1.7rem;
						padding-left: 1.7rem;
						height: 4.8rem;

						&:hover {
							background-color: transparent;
						}
					}
				}
			}
		}

		.rw-picker-btn:disabled,
		fieldset[disabled] .rw-picker-btn {
			background: none;
		}

		.rw-input {
			display: none;
		}
	}
}
